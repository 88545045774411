import React, { useRef, useEffect } from "react";
import "./css/custom.css";
import "./css/style.css";
import "./css/font-icons.css";
import "./css/icons/unicons.css";
import "./css/icons/font-awesome.css";
import "./css/swiper.css";
import "./css/custom.css";
import worksmarter from "./img/IMG_2645.png";
import ainav from "./img/ainavigator.png";
import ws from "./img/ws.png";
import srv from "./img/srv.png";
import logo from "./img/logo.png";
import { Link } from "react-scroll";
import {
  servicesContentLeft,
  servicesContentRight,
  workshopContentLeft,
  workshopContentRight,
} from "./data";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SpeedDial from "@mui/material/SpeedDial";
import MenuIcon from "@mui/icons-material/Menu";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Mail from "@mui/icons-material/Mail";
import Home from "@mui/icons-material/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import ContactForm from "./contactform";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = createTheme({
    components: {
      MuiSpeedDial: {
        styleOverrides: {
          fab: {
            backgroundColor: "var(--bs-btn-bg)",
            color: "var(--bs-btn-color)",
            borderColor: "var(--bs-btn-border-color)",
            "&:hover": {
              backgroundColor: "var(--bs-btn-hover-bg)",
              color: "var(--bs-btn-hover-color)",
              borderColor: "var(--bs-btn-hover-border-color)",
            },
            "&:focus": {
              boxShadow: `0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5)`,
            },
            "&:active": {
              backgroundColor: "var(--bs-btn-active-bg)",
              color: "var(--bs-btn-active-color)",
              borderColor: "var(--bs-btn-active-border-color)",
              boxShadow: "var(--bs-btn-active-shadow)",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--bs-btn-disabled-bg)",
              color: "var(--bs-btn-disabled-color)",
              borderColor: "var(--bs-btn-disabled-border-color)",
            },
          },
        },
      },
    },
  });
  const actions = [
    {
      icon: <Mail />,
      name: "Contact",
      to: "contact",
      component: (
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        ></Link>
      ),
    },

    {
      icon: <HomeRepairServiceIcon />,
      name: "Workshops",
      to: "workshops",
      component: (
        <Link
          to="workshops"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        ></Link>
      ),
    },
    {
      icon: <RoomServiceIcon />,
      name: "Services",
      to: "services",
      component: (
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        ></Link>
      ),
    },
    {
      icon: <Home />,
      name: "Home",
      to: "content",
      component: (
        <Link
          to="content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        ></Link>
      ),
    },
  ];

  const contentRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div id="wrapper">
      <ThemeProvider theme={theme}>
        <SpeedDial
          className="btn-primary"
          ariaLabel="Menu"
          sx={{ position: "fixed", bottom: 16, left: 16 }}
          icon={<MenuIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() =>
                document.getElementById(action.to).scrollIntoView()
              }
            />
          ))}
        </SpeedDial>
      </ThemeProvider>
      <section
        id="slider"
        className="slider-element slider-parallax swiper_wrapper min-vh-60 min-vh-md-100 include-header"
      >
        <div className="slider-inner">
          <div className="swiper swiper-parent">
            <div className="swiper-wrapper">
              <div className="swiper-slide dark">
                <div className="container">
                  <div className="slider-caption slider-caption-center">
                    <motion.h1
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      Welcome to flex.insight
                    </motion.h1>
                    <div className="full-logo">
                      <motion.img
                        src={logo}
                        alt="flex.insight logo"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.8,
                          delay: 0.5,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      />{" "}
                    </div>
                    <motion.h4
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      Your AI Navigator for Growth and Success.
                    </motion.h4>
                    <div className="col-lg-12 align-items-center">
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.8,
                          delay: 0.5,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      ></motion.div>
                      <motion.h4
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.8,
                          delay: 0.5,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      >
                        <div className="promo promo-dark promo-uppercase p-4 p-md-5 mb-5">
                          <div className="row align-items-center">
                            <div className="col-12 col-lg">
                              <h3>
                                Experience our satisfaction guarantee: You only{" "}
                                <span className="text-cta">pay</span> when we
                                improve your business.
                              </h3>
                            </div>
                            <div className="col-12 col-lg-auto mt-4 mt-lg-0">
                              <Link
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="btn btn-lg btn-primary"
                              >
                                Lets Talk!
                              </Link>
                            </div>
                          </div>
                        </div>
                      </motion.h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#content"
              data-offset="100"
              className="dark one-page-arrow"
              onClick={handleClick}
            >
              <i className="bi-chevron-down infinite animated fadeInDown"></i>
            </a>
          </div>
        </div>
      </section>
      <section id="content" ref={contentRef}>
        <div className="content-wrap">
          <div className="section my-0">
            <div className="container">
              <div className="row mt-4 col-mb-50">
                <div className="col-lg-4 d-md-none d-lg-block text-center">
                  <img src={ainav} alt="a rocket" />
                </div>
                <div className="col-lg-8">
                  <div className="heading-block">
                    <h3>Who We Are</h3>
                  </div>
                  <p>
                    We are a pioneering AI advisory firm on a mission to
                    revolutionize how businesses leverage the power of
                    information technology and Artificial Intelligence. Founded
                    by
                    <a
                      href="https://www.linkedin.com/in/tobias-kilga-5a5bb274/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Tobias Kilga
                    </a>
                    , a seasoned technology professional with over a decade of
                    experience in tech and leadership, our firm boasts an
                    extensive healthcare IT background. This comprehensive
                    industry knowledge enables us to build compliant, data
                    privacy-focused IT and AI applications at scale.
                  </p>
                  <p>
                    Headquartered in the innovation hub of Innsbruck, Austria,
                    we go beyond the hype and generic AI implementations. Our
                    approach is tailored to your unique business needs, ensuring
                    we build solutions with you, not just for you. We don't
                    merely implement AI; we seamlessly integrate it into your
                    operations, unlocking its full potential and driving
                    tangible growth.
                  </p>
                  <p>
                    With a proven track record of successful AI implementations
                    and consultations, we understand the challenges businesses
                    face and tailor our solutions to overcome them. Leveraging
                    the latest advancements in AI technology, we drive
                    efficiency, productivity, and profitability for our clients.
                  </p>
                  <p>
                    <b>
                      At flex.insight, we stand behind our expertise and the
                      transformative power of AI. We are committed to delivering
                      results that enhance your business operations, and we
                      offer a risk-free satisfaction guarantee. You only pay if
                      we help you make your business better – guaranteed.
                    </b>
                  </p>
                  <p>
                    We are not just here to consult - we're here to provide
                    tangible value, delivering results rather than rhetoric.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center col-mb-50">
              <div className="col-md-12 text-center text-md-start">
                <div className="heading-block">
                  <h2 className="text-center">
                    If you want to enjoy generic consultation, watch a YouTube
                    video.
                  </h2>
                  <h1 className="text-cta text-center">
                    If you want impact, talk to us.
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center col-mb-50">
              <div className="col-md-8 text-center text-md-start">
                <div className="heading-block">
                  <h2>Ready to Transform Your Business?</h2>
                </div>
                <p>
                  Join the multitude of businesses that have already experienced
                  the game-changing benefits of flex.insight. Don't wait -
                  revolutionize your operations, streamline your processes, and
                  unlock unprecedented growth and success today.
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="btn btn-lg btn-primary"
                >
                  Lets Talk!
                </Link>
              </div>
              <div className="col-lg-4 d-md-none d-lg-block text-center">
                <img src={worksmarter} alt="act now" />
              </div>
            </div>
          </div>
          <div className="container" id="services">
            <div className="heading-block mt-6 text-center">
              <h2>Services</h2>
              <span className="mx-auto">
                We offer modern and innovative services that are tailored to
                your business needs. Our focus is on driving business success
                through technology, delivering solutions that are
                business-focused and results-driven. Partner with us for a new
                era of business excellence.
              </span>
            </div>
            <div className="row align-items-center col-mb-50 mb-4">
              <div className="col-lg-4 col-md-6">
                <div id="services-container-left">
                  {servicesContentLeft.map((item, index) => (
                    <div key={index} className="feature-box mt-5">
                      <div className="fbox-icon">
                        <button
                          onClick={() => navigate(`/details/${item.modalId}`)}
                          className="button button-border button-dark button-rounded button-small ms-0 mt-4"
                        >
                          More
                        </button>{" "}
                      </div>
                      <div className="fbox-content">
                        <h3>{item.heading}</h3>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  ))}
                </div>{" "}
              </div>
              <div className="col-lg-4 d-md-none d-lg-block text-center mt-4">
                <img src={srv} alt="AI Services" />
              </div>
              <div className="col-lg-4 col-md-6">
                <div id="services-container-right">
                  {servicesContentRight.map((item, index) => (
                    <div key={index} className="feature-box mt-5">
                      <div className="fbox-icon">
                        <button
                          onClick={() => navigate(`/details/${item.modalId}`)}
                          className="button button-border button-dark button-rounded button-small ms-0 mt-4"
                        >
                          More
                        </button>{" "}
                      </div>
                      <div className="fbox-content">
                        <h3>{item.heading}</h3>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  ))}
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="section" id="workshops">
            <div className="container">
              <div className="heading-block mt-6 text-center">
                <h2>Workshops</h2>
                <span className="mx-auto">
                  At flex.insight Advisory, we offer interactive and specialized
                  AI workshops tailored to meet your business needs. Our
                  workshops empower your team with practical AI skills, from
                  beginners to advanced users. Partner with us to embrace the
                  future of AI in business and drive innovation in your
                  organization.
                </span>
              </div>

              <div className="row align-items-center col-mb-50 mb-4">
                <div className="col-lg-4 col-md-6">
                  <div id="worskhop-container-left">
                    {workshopContentLeft.map((item, index) => (
                      <div key={index} className="feature-box mt-5">
                        <div className="fbox-icon">
                          <button
                            onClick={() => navigate(`/details/${item.modalId}`)}
                            className="button button-border button-dark button-rounded button-small ms-0 mt-4"
                          >
                            More
                          </button>
                        </div>
                        <div className="fbox-content">
                          <h3>{item.heading}</h3>
                          <p>{item.content}</p>
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                </div>
                <div className="col-lg-4 d-md-none d-lg-block text-center">
                  <img src={ws} alt="AI Services" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <div id="worskhop-container-right">
                    {workshopContentRight.map((item, index) => (
                      <div key={index} className="feature-box mt-5">
                        <div className="fbox-icon">
                          <button
                            onClick={() => navigate(`/details/${item.modalId}`)}
                            className="button button-border button-dark button-rounded button-small ms-0 mt-4"
                          >
                            More
                          </button>{" "}
                        </div>
                        <div className="fbox-content">
                          <h3>{item.heading}</h3>
                          <p>{item.content}</p>
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div id="contact" className="section">
            <div className="container">
              <div className="heading-block text-center">
                <div className="col-md-6 mx-auto">
                  {" "}
                  <ContactForm />{" "}
                </div>
              </div>
            </div>
          </div>
          <footer id="footer" className="dark">
            <div id="copyrights">
              <div className="container">
                <div className="row col-mb-30">
                  <div className="col-md-6 text-center text-md-start">
                    <div className="copyright-links">
                      <a href="#">Imprint</a> /{" "}
                      <a href="/privacy">Privacy Policy</a>/{" "}
                      <a href="mailto:info@flex-insight.com">
                        info@flex-insight.com
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 text-center text-md-end">
                    <div className="d-flex justify-content-center justify-content-md-end mb-2">
                      <a
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/flex-insight"
                        target="_blank"
                        className="social-icon border-transparent si-small me-0 h-bg-linkedin"
                      >
                        <i className="fa-brands fa-linkedin"></i>
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </section>

      <div id="gotoTop" className="uil uil-angle-up"></div>
    </div>
  );
}

export default HomePage;
