export const servicesContentLeft = [
  {
    heading: "AI Consultancy",
    content:
      "Leverage our expertise to accelerate your AI journey. We provide tailored guidance on AI strategy, implementation, and optimization. Our consultants work closely with you to understand your unique needs and develop a roadmap for success.",
    modalId: "aiconsultancymodal",
    format: "Personalized consultation with expert AI consultants.",
    duration: "Variable, depending on project scope.",
    groupSize: "1-on-1 or small group sessions.",
    additional:
      "Customizable engagement models, comprehensive reports, and ongoing support.",
    highlights: [
      "Develop a clear AI strategy aligned with your business goals.",
      "Identify and prioritize AI opportunities within your organization.",
      "Select and implement the right AI tools and technologies.",
      "Mitigate risks and ensure responsible AI adoption.",
    ],
    why:
      "Gain access to expert guidance and accelerate your AI implementation. Ensure your AI initiatives are aligned with your business goals and deliver tangible results. Benefit from our deep industry knowledge and proven track record of success.",
    what:
      "Strategic planning, technology selection, implementation support, performance optimization, risk mitigation, and ongoing guidance.",
    who:
      "Businesses of all sizes looking to leverage AI for growth and innovation. Organizations seeking to optimize their AI investments and achieve maximum impact.",
  },
  {
    heading: "AI Scenario Analysis",
    content:
      "Gain insights into the potential impact of AI on your business. Our experts conduct comprehensive scenario analyses to identify opportunities, risks, and potential outcomes. We help you make informed decisions about AI adoption and mitigate potential challenges.",
    modalId: "aiscenarioanalysismodal",
    format: "In-depth analysis and report with expert recommendations.",
    duration: "Variable, depending on project scope.",
    groupSize: "1-on-1 or small group sessions.",
    additional:
      "Customizable analysis parameters, industry-specific insights, and ongoing support.",
    highlights: [
      "Identify potential AI applications and their impact on your business.",
      "Assess the feasibility and ROI of different AI scenarios.",
      "Understand the potential risks and challenges associated with AI adoption.",
      "Develop a roadmap for responsible and successful AI implementation.",
      "Make informed decisions about AI investments and mitigate potential risks.",
    ],
    why:
      "Gain valuable insights into the potential impact of AI on your business. Make informed decisions about AI adoption and mitigate potential challenges. Ensure your AI initiatives are aligned with your long-term goals and contribute to sustainable growth.",
    what:
      "Comprehensive analysis of AI opportunities, risks, and potential outcomes. Industry-specific insights, expert recommendations, and ongoing support.",
    who:
      "Businesses of all sizes considering AI adoption. Organizations looking to optimize their AI investments and achieve maximum impact.",
  }
];

  export const servicesContentRight =[
    
  {
    heading: "AI Trainings",
    content:
      "Develop your team's AI expertise with our customized training programs. We offer a range of courses covering various AI topics, from fundamentals to advanced applications. Our engaging and interactive sessions are designed to empower your team to leverage AI effectively.",
    modalId: "aitrainingsmodal",
    format: "Interactive workshops, online courses, and customized training programs.",
    duration: "Variable, depending on the chosen program.",
    groupSize: "Small groups or large-scale training sessions.",
    additional:
      "Tailored curriculum, hands-on exercises, expert instructors, and post-training support.",
    highlights: [
      "Develop a strong foundation in AI concepts and technologies.",
      "Learn how to apply AI to solve real-world business problems.",
      "Gain hands-on experience with popular AI tools and techniques.",
      "Empower your team to become confident and effective AI users.",
      "Customize training programs to meet your specific needs and goals."
    ],
    why:
      "Equip your team with the knowledge and skills needed to leverage AI effectively. Enhance your organization's AI capabilities and drive innovation. Ensure your team is prepared for the future of work and can contribute to your success.",
    what:
      "AI fundamentals, advanced applications, industry-specific training, hands-on exercises, expert guidance, and ongoing support.",
    who:
      "Individuals and teams looking to enhance their AI knowledge and skills. Businesses seeking to upskill their workforce and prepare for the future of AI.",
  },
    {
      heading: "AI Infrastructure Optimization",
      content:
        "Maximize the performance and efficiency of your AI infrastructure. Our experts help you design, build, and optimize your AI infrastructure to meet your specific needs. We ensure your systems are scalable, reliable, and cost-effective.",
      modalId: "aiinfraoptimizationmodal",
      format: "Personalized consultation and optimization services.",
      duration: "Variable, depending on project scope.",
      groupSize: "1-on-1 or small group sessions.",
      additional:
        "Customizable optimization plans, performance monitoring, and ongoing support.",
      highlights:
      [
        "Optimize your AI infrastructure for performance, scalability, and reliability.",
        "Reduce costs and improve resource utilization.",
        "Ensure your infrastructure meets your current and future AI needs.",
        "Implement best practices for AI infrastructure management.",
      ],
      why:
        "Maximize the return on your AI investment by optimizing your infrastructure. Ensure your systems are efficient, reliable, and scalable to support your growing AI needs. Benefit from our expertise and proven track record of success.",
      what:
        "Infrastructure assessment, optimization recommendations, implementation support, performance monitoring, and ongoing guidance.",
      who:
        "Businesses of all sizes with existing AI infrastructure. Organizations looking to improve the performance, scalability, and cost-effectiveness of their AI systems.",
    },
  ];
  

export const workshopContentLeft = 
  [
    {
      heading: "Introduction to Generative AI Workshop",
      content:
        "Dive into ChatGPT and other AI tools with practical demonstrations. Learn how to use these tools for content creation, brainstorming, and problem-solving. Explore the ethical implications of using generative AI and discuss its future potential.",
      modalId: "1",
      format: "Interactive workshop with hands-on exercises and group discussions.",
      duration: "2 hours",
      groupSize: "Max 15 participants",
      additional:
        "Handouts, post-workshop FAQ support, and access to a private online community for continued learning and networking.",
      highlights: [
        "Explore the fundamentals of generative AI, including its capabilities, limitations, and ethical considerations.",
        "Get hands-on experience with popular generative AI tools like ChatGPT, DALL-E, and Midjourney.",
        "Discover practical applications of generative AI in various industries, such as content creation, marketing, design, and research.",
        "Learn how to use generative AI tools effectively to enhance your creativity, productivity, and problem-solving abilities.",
      ],
      why:
        "Gain a solid understanding of generative AI and its potential to revolutionize various aspects of your work and life. Learn how to use popular generative AI tools effectively to enhance your creativity, productivity, and problem-solving abilities. Discover practical applications of generative AI in your field and explore its potential to transform your industry.",
      what:
        "With this course, you will gain a solid foundation in the fundamentals of generative AI. You will acquire hands-on experience with tools such as ChatGPT, enabling you to understand and apply practical applications of generative AI. The course also covers the ethical considerations that come with using generative AI, ensuring you are fully informed of its implications. Lastly, you will explore the future of generative AI, equipping you with a forward-looking perspective on this transformative technology.",
      who:
        "Anyone interested in learning about generative AI. Content creators, writers, designers, and marketers. Business professionals who want to understand how generative AI can impact their industry.",
    },
    {
      heading: "AI Solutions for Business Growth Workshop",
      content:
        "Explore how AI drives business expansion and aids decision-making. Learn how to identify areas within your organization where AI can add the most value and develop strategies for implementing AI solutions that align with your business goals.",
      modalId: "aisolutionsmodal",
      format: "Interactive workshop with case studies, group discussions, and Q&A sessions.",
      duration: "4 hours",
      groupSize: "Max 10 participants",
      additional:
        "Handouts, post-workshop FAQ support, and access to a curated list of AI resources and tools.",
      highlights: [
        "Discover how AI can improve efficiency, productivity, and customer satisfaction through real-world case studies.",
        "Learn how to leverage AI insights to make better decisions, optimize operations, and gain a competitive edge.",
        "Explore successful examples of businesses using AI for growth in various industries, including marketing, sales, finance, and operations.",
        "Identify AI opportunities within your business and develop strategies for implementing AI solutions that align with your goals.",
      ],
      why:
        "Understand the benefits and applications of AI for business growth and gain insights from real-world case studies. Learn from successful AI implementations and discover practical strategies for identifying and implementing AI solutions in your organization. Develop the skills and knowledge to leverage AI insights for better decision-making and optimize your business operations.",
      what:
        "Benefits and applications of AI for business growth. Real-world case studies of successful AI implementations. Strategies for identifying and implementing AI solutions. Steps involved in implementing AI solutions.",
      who:
        "Business owners, executives, and decision-makers. Marketing, sales, and operations professionals. Anyone interested in understanding how AI is transforming the business world.",
    },
    {
      heading: "AI for Executives Workshop",
      content:
        "Gain a comprehensive understanding of AI and its implications for business strategy. Explore the latest AI trends, learn how to identify AI opportunities, and develop a roadmap for implementing AI in your organization.",
      modalId: "ai-for-executives-workshop",
      format: "Interactive workshop with case studies, group discussions, and expert presentations.",
      duration: "3 hours",
      groupSize: "Max 12 participants",
      additional:
        "Handouts, post-workshop FAQ support, and access to a curated list of AI resources and tools.",
      highlights:
    [
        "Gain a high-level understanding of AI concepts, technologies, and applications.",
        "Explore the latest AI trends and their potential impact on your industry.",
        "Learn how to identify AI opportunities within your organization and develop a roadmap for implementation.",
        "Discover successful examples of businesses using AI to achieve their strategic goals.",
          ],
                why:
        "Gain a comprehensive understanding of AI and its implications for business strategy. Learn how to identify AI opportunities, develop a roadmap for implementation, and ensure your organization is prepared for the future of AI. Benefit from the insights of industry experts and engage in interactive discussions with fellow executives.",
      what:
        "AI fundamentals, latest trends, identifying AI opportunities, developing an AI roadmap, successful AI implementations, and the future of AI.",
      who:
        "Business executives, CEOs, and decision-makers. Anyone interested in understanding the strategic implications of AI for their organization.",
    },
];

export const workshopContentRight = [
  {
    heading: "Effective Use of AI Text Generators Workshop",
    content:
      "Explore AI text generators like Google Gemini and ChatGPT for brainstorming and content creation. Learn how to use these tools to overcome writer's block, generate creative ideas, and improve your writing efficiency. Understand best practices and ethical considerations for using AI text generators responsibly.",
    modalId: "aitextgenmodal",
    format: "Interactive workshop with hands-on exercises, group brainstorming sessions, and individual feedback.",
    duration: "2.5 hours",
    groupSize: "Max 8 participants",
    additional:
      "Handouts, post-workshop FAQ support, and access to a curated list of AI text generator prompts and resources.",
    highlights: [
      "Gain a solid understanding of how AI text generators work, including their capabilities, limitations, and ethical considerations.",
      "Learn how to use Google Gemini and ChatGPT effectively for brainstorming, content creation, and idea generation.",
      "Discover techniques for using AI text generators to overcome writer's block, generate creative ideas, and improve your writing efficiency.",
      "Learn how to write and edit various types of content with AI text generators, including articles, blog posts, social media content, and marketing materials.",
    ],
    why:
      "Learn how to use AI text generators to generate ideas, write content, and edit your work more efficiently. Discover techniques for using AI text generators effectively for different content types and improve your writing skills. Gain a deeper understanding of the capabilities and limitations of AI text generators and use them responsibly to enhance your creativity.",
    what:
      "AI text generator fundamentals and capabilities. Effective use of Google Gemini and ChatGPT for content creation. Techniques for brainstorming and idea generation using AI text generators. Best practices and ethical considerations for using AI text generators.",
    who:
      "Content creators, writers, and marketers. Business professionals who need to write reports, emails, and other documents. Anyone interested in learning how AI text generators can improve their writing efficiency.",
  },
  {
    heading: "Creating Images with AI Tools Workshop",
    content:
      "Master image creation using AI tools like Midjourney and Dall-E. Learn how to use these tools for concept art, product mockups, and social media graphics. Understand best practices for generating high-quality images that meet your specific needs.",
    modalId: "aiimagemodal",
    format: "Interactive workshop with hands-on exercises, group critiques, and individual feedback.",
    duration: "3 hours",
    groupSize: "Max 10 participants",
    additional:
      "Handouts, post-workshop FAQ support, and access to a private online community for sharing your creations and receiving feedback.",
    highlights: [
      "Gain a deep understanding of AI image generation concepts and technologies, including prompt engineering, diffusion models, and generative adversarial networks.",
      "Learn how to use Midjourney and Dall-E effectively for image creation, including prompt writing, image editing, and style transfer techniques.",
      "Discover best practices for generating high-quality images that meet your specific needs, including resolution, aspect ratio, and color palette.",
      "Explore advanced techniques for creating unique and visually compelling images, such as inpainting, outpainting, and style mixing.",
    ],
    why:
      "Learn how to use AI image generation tools to create stunning visuals for your projects. Discover the power of AI to bring your ideas to life and enhance your creative workflow. Gain the skills and knowledge to generate high-quality images that meet your specific needs and impress your audience.",
    what:
      "AI image generation fundamentals and technologies. Effective use of Midjourney and Dall-E for image creation. Best practices for generating high-quality images. Advanced techniques for creating unique and visually compelling images.",
    who:
      "Content creators, designers, marketers, and anyone interested in using AI to create stunning visuals.",
  },
  {
    heading: "Mastering Prompting Techniques for Businesses Workshop",
    content:
      "Enhance AI interaction skills with advanced business prompting techniques. Learn how to adapt your prompting style to different AI models and business scenarios. Understand the ethical implications of prompting and how to use AI responsibly.",
    modalId: "mastering-prompting-techniques",
    format: "Interactive workshop with hands-on exercises and group discussions.",
    duration: "3 hours",
    groupSize: "Max 10 participants",
    additional:
      "Handouts, post-workshop FAQ support, and access to a curated list of prompting resources and tools.",
    highlights: [
      "Gain a solid understanding of prompting concepts, principles, and best practices.",
      "Discover advanced prompting techniques specifically designed for business applications.",
      "Learn how to adapt your prompting style to different AI models and business scenarios.",
      "Understand the ethical implications of prompting and how to use AI responsibly.",
    ],
    why:
      "Master advanced prompting techniques specifically designed for business applications. Improve your communication with AI models and achieve better results. Discover creative ways to use AI to solve business challenges and generate new opportunities.",
    what:
      "Prompting fundamentals and best practices. Advanced prompting techniques for business applications. Adapting prompting styles to different AI models and business scenarios. Ethical implications of prompting and responsible AI use.",
    who:
      "Business owners, executives, and decision-makers. Marketing, sales, and operations professionals who use AI tools. Anyone interested in learning advanced prompting techniques for business applications.",
  },
];
  export function generateHTML(data) {
    let html = "";
    data.forEach(function (item) {
      html += `
        <div class="feature-box mt-5" data-animate="fadeIn" data-delay="200">
          <div class="fbox-icon">
            <button data-bs-toggle="modal" data-bs-target="#${item.modalId}" class="button button-border button-dark button-rounded button-small ms-0 mt-4">More</button>
          </div>
          <div class="fbox-content">
            <h3>${item.heading}</h3>
            <p>${item.content}</p>
          </div>
        </div>`;
    });
    return html;
  }