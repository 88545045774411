import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function PrivacyPage() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };

  return (
    <section id="content">
      <div class="content-wrap">
        <div class="container">
          <button
            onClick={goBack}
            className="uil uil-angle-left btn btn-lg btn-primary"
            style={{ position: "fixed", bottom: "20px", left: "20px" }}
          ></button>

          <div class="section parallax mb-5 border-0 dark" data-bs-theme="dark">
            <div class="vertical-middle text-center">
              <div class="container fadeInUp animated" data-animate="fadeInUp">
                <div class="heading-block border-bottom-0 mb-0">
                  <h2>Privacy</h2>
                  <span class="d-none d-lg-block">
                    <p>
                      Privacy The protection of personal data is our top
                      priority. The processing of your data is always
                      confidential and always GDPR-compliant or in the sense of
                      the legal provisions according to Austrian data protection
                      law (DSG), as well as the aforementioned EU General Data
                      Protection Regulation (GDPR). On the following page, we
                      inform you about details and the most important aspects
                      regarding our data protection obligations.
                    </p>
                    <p>
                      Contact details and responsible body: Flex Insight Mühlweg
                      3, 6071 Aldrans Tyrol, Austria E-Mail{" "}
                      <a
                        href="mailto:info@flex-insight.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@flex-insight.com
                      </a>
                    </p>
                    <h4>Which data are processed</h4>
                    <p>
                      By visiting our website, the following data are collected:
                      Date & time of the call of this website IP address Version
                      & name of your web browser Used operating system Referrer
                      URL (= website, which you called before the visit of our
                      website). Purpose of data processing Your personal data
                      are strictly confidential, and serve only the following
                      purposes: Improvement and further development of the
                      website in the sense of user-friendliness Creation of
                      statistics, which give information on the use of our
                      website Defend, prevent and prevent attacks.
                    </p>
                    <h4>Cookie policies</h4>
                    <p>
                      You have the possibility to prevent the storage of the
                      cookie on your device by making appropriate settings in
                      your browser. It is not guaranteed that you can access all
                      functions of this website without restrictions if your
                      browser does not allow cookies. Further information on
                      data use by Google Inc. can be found here. Note on
                      affected rights In the sense of the GDPR, you count as
                      affected if personal data concerning you are processed by
                      us. For this reason, you can make use of various affected
                      rights, which are anchored in the General Data Protection
                      Regulation. These are the right to information (Article 15
                      GDPR), the right to correction (Article 16 GDPR), the
                      right to deletion (Article 17 GDPR), the right to
                      restriction of processing (Article 18 GDPR), the right to
                      objection (Article 21 GDPR), the right to complain to a
                      supervisory authority (Article 77 GDPR) and the right to
                      data portability (Article 20 GDPR).
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPage;
