import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Spinner } from "react-bootstrap";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  customColor: {
    main: "#F4C844",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F4C844",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F4C844",
          },
        },
        input: {
          "&:valid": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#F4C844",
          },
          "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            color: "#F4C844",
          },
        },
      },
    },
  },
});

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState("Submit");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const { name, email, message } = e.target;
    const endpoint =
      "https://8jl5b8gmqi.execute-api.us-east-1.amazonaws.com/default/sendContactEmail";

    setStatus("Sending...");
    const body = {
      senderName: name.value,
      senderEmail: email.value,
      message: message.value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
      },
      mode: "no-cors", // Add this line
      body: JSON.stringify(body),
    };
    console.log(requestOptions);
    fetch(endpoint, requestOptions).then((response) => {});
    setStatus("Submit");
    setIsSending(false);
    setIsSubmitted(true);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      {isSubmitted ? (
        <div>
          <h2>
            Your message has been received! Our AI advisors are excited to
            connect with you soon.
          </h2>
        </div>
      ) : (
        <>
          <h2>Lets Talk!</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                required
                fullWidth
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                required
                fullWidth
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="message"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                required
                fullWidth
                margin="normal"
              />
            </div>
            <button type="submit" className="btn btn-lg btn-primary mt-4">
              {isSending ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Sending...</span>
                </Spinner>
              ) : (
                "Send"
              )}
            </button>
          </form>
        </>
      )}
    </ThemeProvider>
  );
};

export default ContactForm;
