import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  servicesContentLeft,
  servicesContentRight,
  workshopContentLeft,
  workshopContentRight,
} from "./data";

function DetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const content = [
    ...servicesContentLeft,
    ...servicesContentRight,
    ...workshopContentLeft,
    ...workshopContentRight,
  ];
  // Find the object with the matching ID
  const item = content.find((item) => item.modalId === id);

  return (
    <section id="content">
      <div class="content-wrap">
        <div class="container">
          <div class="section parallax mb-5 border-0 dark" data-bs-theme="dark">
            <div class="vertical-middle text-center">
              <div class="container fadeInUp animated" data-animate="fadeInUp">
                <div class="heading-block border-bottom-0 mb-0">
                  <h2>{item.heading}</h2>
                  <span class="d-none d-lg-block">{item.content}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-mb-0">
            {item.highlights.map((highlight, index) => {
              const [title, content] = highlight.split(": ");
              return (
                <div key={index} class="col-sm-6 col-lg-6 mb-4">
                  <div class="feature-box fbox-rounded">
                    <div class="fbox-icon">
                      <a href="#">
                        <i class="bi-lightbulb i-alt"></i>
                      </a>
                    </div>
                    <div class="fbox-content">
                      <h3>{title}</h3>
                      <p>{content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div class="row align-items-stretch">
          <div
            class="col-lg-6 dark col-padding overflow-hidden"
            data-bs-theme="dark"
          >
            <div>
              <h3 class="text-uppercase">Why Choose This</h3>
              <p>{item.why}</p>{" "}
            </div>
          </div>

          <div
            class="col-lg-6 dark col-padding overflow-hidden"
            data-bs-theme="dark"
          >
            <div>
              <h3 class="text-uppercase">What You Get</h3>
              <p>{item.what}</p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="pricing-box pricing-extended row align-items-stretch mb-5 mx-0">
            <div class="pricing-action-area col-lg d-flex flex-column justify-content-center">
              <div class="pricing-meta">As Low as</div>
              <div class="pricing-price">
                <span class="price-unit">€</span>xx
                <span class="price-tenure">per person</span>
              </div>
              <div class="pricing-action col-md-4 offset-md-4">
                <a href="#" class="button button-3d button-large w-100 m-0">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={goBack}
        className="uil uil-angle-left btn btn-lg btn-primary back-button"
      ></button>
    </section>
  );
}

export default DetailsPage;
