import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import DetailsPage from "./DetailsPage";
import PrivacyPage from "./privacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/details/:id" element={<DetailsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />

        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
